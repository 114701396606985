<template>
  <div class="van-main has-fixed-btn">
    <div class="van-common-detail">
      <van-tabs v-model="activeName">
        <van-tab name="info" title="提货详情">
          <van-cell-group title="提货信息">
            <van-cell title="提货申请编号" :value="utils.isEffectiveCommon(outWarehouseDetail.applyNo)" />
            <van-cell title="关联订单" :value="utils.isEffectiveCommon(outWarehouseDetail.orderName)" />
            <van-cell title="订单编号" :value="utils.isEffectiveCommon(outWarehouseDetail.orderNo)" />
            <van-cell title="关联主合同" :value="utils.isEffectiveCommon(outWarehouseDetail.contractName)" />
            <van-cell title="合同编号" :value="utils.isEffectiveCommon(outWarehouseDetail.contractNumber)" />
            <van-cell title="仓储方" :value="utils.isEffectiveCommon(outWarehouseDetail.warehouseEnterpriseName)" />
            <van-cell title="仓库名称" :value="utils.isEffectiveCommon(outWarehouseDetail.state == 1 ? outWarehouseDetail.warehouseName + '（已锁定)' : outWarehouseDetail.warehouseName + '(未锁定)')" />
            <van-cell title="提货类型" :value="utils.statusFormat(outWarehouseDetail.outType, 'outType')" />
            <van-cell title="申请提货日期" :value="utils.isEffectiveCommon(outWarehouseDetail.newApplyDate)" />
            <van-cell title="提货方式" :value="utils.statusFormat(outWarehouseDetail.outMethod, 'outMethod')" />
            <van-cell title="提货总件数" :value="utils.isEffectiveCommon(outWarehouseDetail.applyNumber)" />
            <van-cell title="申请提货总金额" :value="utils.isEffectiveCommon(totalApplyNumber)" />
            <div class="van-common-detail">
              <van-cell>
                <div class="goods-table">
                  <table v-for="(item,idx) in pickUpInfoVoList" :key="item.id" class="inner-goods-table" border="1">
                    <tr>
                      <th colspan="2" class="header-th">
                        <span>{{ idx+1 }}</span>
                      </th>
                    </tr>
                    <tr>
                      <td>柜号</td><td>{{ item.cabinetNo }}</td>
                    </tr>
                    <tr>
                      <td>国家</td><td>{{ utils.statusFormat(item.country,'Country') }}</td>
                    </tr>
                    <tr>
                      <td>原产地</td><td>{{ item.countryOfOrigin }}</td>
                    </tr>
                    <tr>
                      <td>HS编码</td><td>{{ item.hsCode }}</td>
                    </tr>
                    <tr>
                      <td>货物名称</td><td>{{ item.cargoName }}</td>
                    </tr>
                    <tr>
                      <td>箱型</td><td>{{ item.boxType }}</td>
                    </tr>
                    <tr>
                      <td>账面件数</td><td>{{ item.pieceNumber }}</td>
                    </tr>
                    <tr>
                      <td>账面重量（KGS）</td><td>{{ item.actualWeight }}</td>
                    </tr>
                    <tr>
                      <td>总金额</td><td>{{ item.money }}</td>
                    </tr>
                  </table>
                </div>
              </van-cell>
            </div>
          </van-cell-group>
          <van-cell-group title="货物明细">
            <div class="van-common-detail">
              <van-cell>
                <div class="goods-table">
                  <table v-for="(item) in goodsList" :key="item.id" class="inner-goods-table" border="1">
                    <tr>
                      <td>商品编号</td><td>{{ item.cargoNo }}</td>
                    </tr>
                    <tr>
                      <td>品名</td><td>{{ item.productName }}</td>
                    </tr>
                    <tr>
                      <td>单价(原币)</td><td>{{ item.unitPrice }}</td>
                    </tr>
                    <tr>
                      <td>认定单价（元/件）</td><td>{{ item.packageUnitPrice }}</td>
                    </tr>
                    <tr>
                      <td>账面件数</td><td>{{ item.pieceNumber }}</td>
                    </tr>
                    <tr>
                      <td>账面重量（KG）</td><td>{{ item.weight }}</td>
                    </tr>
                    <tr>
                      <td>申请提货件数</td><td>{{ item.applyNumber }}</td>
                    </tr>
                  </table>
                </div>
              </van-cell>
            </div>
          </van-cell-group>
          <van-cell-group title="装车信息">
            <div class="table goods-table">
              <table class="inner-goods-table">
                <thead>
                  <tr>
                    <th>车牌号</th>
                    <th>联系人</th>
                    <th>联系电话</th>
                    <th>驾驶证号或身份证号</th>
                    <th>备注</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in vehiclesInfoVoList" :key="item.materialId">
                    <td>{{ utils.isEffectiveCommon(item.numberPlates) }}</td>
                    <td>{{ utils.isEffectiveCommon(item.contacts) }}</td>
                    <td>{{ utils.isEffectiveCommon(item.phone) }}</td>
                    <td>{{ utils.isEffectiveCommon(item.certificates) }}</td>
                    <td>{{ utils.isEffectiveCommon(item.remarks) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </van-cell-group>
        </van-tab>
        <van-tab name="delivery" title="放货要件">
          <div class="table goods-table">
            <table class="inner-goods-table">
              <thead>
                <tr>
                  <th>放货要件</th>
                  <th>状态</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in loanData" :key="item.id">
                  <td>{{ utils.isEffectiveCommon(item.licenceName) }}</td>
                  <td>
                    <p v-if="item.licenceName === '完成收款'">{{ appDownList.length !== 0 ? '已完成' : '未完成' }}</p>
                    <p v-else>{{ item.fileDetailInfoVo ? '已完成' : '未完成' }}</p>
                  </td>
                  <td>
                    <a v-if="item.licenceName === '完成收款'" class="text-btn" href="javascript:" @click="releaseAuditDetail">收款结果</a>
                    <van-uploader v-else v-model="item.fileDetailInfoVoList" :show-upload="false" :deletable="false" disabled @click-preview="preview" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </van-tab>
        <van-tab name="audit" title="审批意见">
          <workflow-audit v-if="Number($route.query.taskViewMode)!==0" page-key="discharging_cargo" :business-key="$route.query.stockOutId" :proc-inst-id="$route.query.procInstId" :variable="{businessId:$route.query.stockOutId,motif:`${outWarehouseDetail.enterpriseShortName}-${outWarehouseDetail.warehouseName}`}" :view-mode="true" @confirmAudit="utils.backReturn($route.query.messageType)" />
          <workflow-audit v-else page-key="discharging_cargo" :business-key="$route.query.stockOutId" :task-id="$route.query.taskId" :proc-inst-id="$route.query.procInstId" :variable="{businessId:$route.query.stockOutId,motif:`${outWarehouseDetail.enterpriseShortName}-${outWarehouseDetail.warehouseName}`}" @confirmAudit="utils.backReturn($route.query.messageType)" />
        </van-tab>
      </van-tabs>
    </div>
    <van-row class="fixed-btn-bottom" gutter="20">
      <van-button type="info" color="#1373CC" block text="返回" @click="utils.backReturn($route.query.messageType)" />
    </van-row>
  </div>
</template>

<script>
import { Tab, Tabs, Cell, CellGroup, Row, Button, Uploader, Checkbox, CheckboxGroup } from 'vant'
import WorkflowAudit from '@/components/workflow-audit'
// import fileList from '@/components/file-list'
export default {
  components: {
    WorkflowAudit,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup
  },
  data () {
    return {
      activeName: 'info',
      outWarehouseDetail: {},
      quotaDetail: {},
      qualificationList: [],
      businessLicenseList: [],
      fileBusinessSnImgsList: [],
      accountInfoVoList: [],
      pickUpInfoVoList: [],
      goodsList: [],
      vehiclesInfoVoList: [],
      totalApplyNumber: 0,
      productName: '',
      loanData: [],
      collectionAmountSum: 0,
      amountPaid: 0,
      appDownList: []
    }
  },
  created () {
    this.getOutWarehouseDetail()
    this.getLicenceList()
    this.getInfoByCollectionId()
  },
  methods: {
    // 处理文件数组
    getFileName (fileUrl) {
      const splitList = fileUrl ? fileUrl.split('/') : []
      return splitList[splitList.length - 1] || ''
    },
    getOutWarehouseDetail () {
      this.utils.loading()
      this.api.task.outWarehouse.getDetail(this.$route.query.stockOutId).then(result => {
        const productNameList = []
        result.data.value.containerInfoVo.goodsList.forEach(item => {
          productNameList.push(item.productName)
        })
        this.productName = productNameList.join(',')
        this.outWarehouseDetail = result.data.value
        this.pickUpInfoVoList = [...[], ...[result.data.value.containerInfoVo.pickUpInfoVo]]
        this.goodsList = [...[], ...result.data.value.containerInfoVo.goodsList]
        this.vehiclesInfoVoList = [...[], ...[result.data.value.vehiclesInfoVo]]
        this.getInfoByCollectionId()
      }).finally(() => {
        this.utils.closeLoading()
      })
    },
    // 根据申请付款UUID查询未提交除外付款结果
    getInfoByCollectionId () {
      this.api.task.outWarehouse.getInfoByCollectionId(this.outWarehouseDetail.collectionId).then(res => {
        this.appDownList = res.data.value || []
        this.collectionAmountSum = this.appDownList.length !== 0 ? this.appDownList[0].collectionAmountSum || 0 : 0
        this.appDownList.forEach(item => {
          item.collectionDate = (item.collectionDate || '').split('T')[0]
          if (item.resultStatus === 3) {
            this.amountPaid = this.amountPaid + ((item.collectionAmount || 0) * 1000)
          }
        })
      })
    },
    getLicenceList () {
      this.api.task.outWarehouse.licenceList(this.$route.query.stockOutId).then(result => {
        result.data.value.fileDetailInfoVoList = [result.data.value.fileDetailInfoVo]
        this.loanData = result.data.value || []
      })
    },
    releaseAuditDetail () {
      this.$router.push({ name: 'releaseAuditDetail', query: { collectionId: this.outWarehouseDetail.collectionId } })
    },
    preview (file, detail) {
      const fileTypeArr = file.fileType.split('/')
      if (fileTypeArr[0] !== 'image') {
        window.open(file.fileUrl)
      }
    }
  }
}
</script>

<style scoped>
  .certificate-photo >>> .van-uploader__wrapper {
    display: inline-flex !important;
    flex-wrap: inherit !important;
  }
  .van-common-detail >>> .van-tabs .van-tabs__wrap .van-tabs__nav:first-child .van-tab{
    padding: 0 !important;
  }
</style>
