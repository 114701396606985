var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main has-fixed-btn" },
    [
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "van-tab",
                { attrs: { name: "info", title: "提货详情" } },
                [
                  _c(
                    "van-cell-group",
                    { attrs: { title: "提货信息" } },
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "提货申请编号",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.outWarehouseDetail.applyNo
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "关联订单",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.outWarehouseDetail.orderName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "订单编号",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.outWarehouseDetail.orderNo
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "关联主合同",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.outWarehouseDetail.contractName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "合同编号",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.outWarehouseDetail.contractNumber
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "仓储方",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.outWarehouseDetail.warehouseEnterpriseName
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "仓库名称",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.outWarehouseDetail.state == 1
                              ? _vm.outWarehouseDetail.warehouseName +
                                  "（已锁定)"
                              : _vm.outWarehouseDetail.warehouseName +
                                  "(未锁定)"
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "提货类型",
                          value: _vm.utils.statusFormat(
                            _vm.outWarehouseDetail.outType,
                            "outType"
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "申请提货日期",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.outWarehouseDetail.newApplyDate
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "提货方式",
                          value: _vm.utils.statusFormat(
                            _vm.outWarehouseDetail.outMethod,
                            "outMethod"
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "提货总件数",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.outWarehouseDetail.applyNumber
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "申请提货总金额",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.totalApplyNumber
                          )
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "van-common-detail" },
                        [
                          _c("van-cell", [
                            _c(
                              "div",
                              { staticClass: "goods-table" },
                              _vm._l(_vm.pickUpInfoVoList, function(item, idx) {
                                return _c(
                                  "table",
                                  {
                                    key: item.id,
                                    staticClass: "inner-goods-table",
                                    attrs: { border: "1" }
                                  },
                                  [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass: "header-th",
                                          attrs: { colspan: "2" }
                                        },
                                        [_c("span", [_vm._v(_vm._s(idx + 1))])]
                                      )
                                    ]),
                                    _c("tr", [
                                      _c("td", [_vm._v("柜号")]),
                                      _c("td", [_vm._v(_vm._s(item.cabinetNo))])
                                    ]),
                                    _c("tr", [
                                      _c("td", [_vm._v("国家")]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.utils.statusFormat(
                                              item.country,
                                              "Country"
                                            )
                                          )
                                        )
                                      ])
                                    ]),
                                    _c("tr", [
                                      _c("td", [_vm._v("原产地")]),
                                      _c("td", [
                                        _vm._v(_vm._s(item.countryOfOrigin))
                                      ])
                                    ]),
                                    _c("tr", [
                                      _c("td", [_vm._v("HS编码")]),
                                      _c("td", [_vm._v(_vm._s(item.hsCode))])
                                    ]),
                                    _c("tr", [
                                      _c("td", [_vm._v("货物名称")]),
                                      _c("td", [_vm._v(_vm._s(item.cargoName))])
                                    ]),
                                    _c("tr", [
                                      _c("td", [_vm._v("箱型")]),
                                      _c("td", [_vm._v(_vm._s(item.boxType))])
                                    ]),
                                    _c("tr", [
                                      _c("td", [_vm._v("账面件数")]),
                                      _c("td", [
                                        _vm._v(_vm._s(item.pieceNumber))
                                      ])
                                    ]),
                                    _c("tr", [
                                      _c("td", [_vm._v("账面重量（KGS）")]),
                                      _c("td", [
                                        _vm._v(_vm._s(item.actualWeight))
                                      ])
                                    ]),
                                    _c("tr", [
                                      _c("td", [_vm._v("总金额")]),
                                      _c("td", [_vm._v(_vm._s(item.money))])
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("van-cell-group", { attrs: { title: "货物明细" } }, [
                    _c(
                      "div",
                      { staticClass: "van-common-detail" },
                      [
                        _c("van-cell", [
                          _c(
                            "div",
                            { staticClass: "goods-table" },
                            _vm._l(_vm.goodsList, function(item) {
                              return _c(
                                "table",
                                {
                                  key: item.id,
                                  staticClass: "inner-goods-table",
                                  attrs: { border: "1" }
                                },
                                [
                                  _c("tr", [
                                    _c("td", [_vm._v("商品编号")]),
                                    _c("td", [_vm._v(_vm._s(item.cargoNo))])
                                  ]),
                                  _c("tr", [
                                    _c("td", [_vm._v("品名")]),
                                    _c("td", [_vm._v(_vm._s(item.productName))])
                                  ]),
                                  _c("tr", [
                                    _c("td", [_vm._v("单价(原币)")]),
                                    _c("td", [_vm._v(_vm._s(item.unitPrice))])
                                  ]),
                                  _c("tr", [
                                    _c("td", [_vm._v("认定单价（元/件）")]),
                                    _c("td", [
                                      _vm._v(_vm._s(item.packageUnitPrice))
                                    ])
                                  ]),
                                  _c("tr", [
                                    _c("td", [_vm._v("账面件数")]),
                                    _c("td", [_vm._v(_vm._s(item.pieceNumber))])
                                  ]),
                                  _c("tr", [
                                    _c("td", [_vm._v("账面重量（KG）")]),
                                    _c("td", [_vm._v(_vm._s(item.weight))])
                                  ]),
                                  _c("tr", [
                                    _c("td", [_vm._v("申请提货件数")]),
                                    _c("td", [_vm._v(_vm._s(item.applyNumber))])
                                  ])
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      ],
                      1
                    )
                  ]),
                  _c("van-cell-group", { attrs: { title: "装车信息" } }, [
                    _c("div", { staticClass: "table goods-table" }, [
                      _c("table", { staticClass: "inner-goods-table" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("车牌号")]),
                            _c("th", [_vm._v("联系人")]),
                            _c("th", [_vm._v("联系电话")]),
                            _c("th", [_vm._v("驾驶证号或身份证号")]),
                            _c("th", [_vm._v("备注")])
                          ])
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.vehiclesInfoVoList, function(item) {
                            return _c("tr", { key: item.materialId }, [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(
                                      item.numberPlates
                                    )
                                  )
                                )
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(item.contacts)
                                  )
                                )
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(item.phone)
                                  )
                                )
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(
                                      item.certificates
                                    )
                                  )
                                )
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(item.remarks)
                                  )
                                )
                              ])
                            ])
                          }),
                          0
                        )
                      ])
                    ])
                  ])
                ],
                1
              ),
              _c(
                "van-tab",
                { attrs: { name: "delivery", title: "放货要件" } },
                [
                  _c("div", { staticClass: "table goods-table" }, [
                    _c("table", { staticClass: "inner-goods-table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("放货要件")]),
                          _c("th", [_vm._v("状态")]),
                          _c("th", [_vm._v("操作")])
                        ])
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.loanData, function(item) {
                          return _c("tr", { key: item.id }, [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(item.licenceName)
                                )
                              )
                            ]),
                            _c("td", [
                              item.licenceName === "完成收款"
                                ? _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.appDownList.length !== 0
                                          ? "已完成"
                                          : "未完成"
                                      )
                                    )
                                  ])
                                : _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        item.fileDetailInfoVo
                                          ? "已完成"
                                          : "未完成"
                                      )
                                    )
                                  ])
                            ]),
                            _c(
                              "td",
                              [
                                item.licenceName === "完成收款"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "text-btn",
                                        attrs: { href: "javascript:" },
                                        on: { click: _vm.releaseAuditDetail }
                                      },
                                      [_vm._v("收款结果")]
                                    )
                                  : _c("van-uploader", {
                                      attrs: {
                                        "show-upload": false,
                                        deletable: false,
                                        disabled: ""
                                      },
                                      on: { "click-preview": _vm.preview },
                                      model: {
                                        value: item.fileDetailInfoVoList,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item,
                                            "fileDetailInfoVoList",
                                            $$v
                                          )
                                        },
                                        expression: "item.fileDetailInfoVoList"
                                      }
                                    })
                              ],
                              1
                            )
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "van-tab",
                { attrs: { name: "audit", title: "审批意见" } },
                [
                  Number(_vm.$route.query.taskViewMode) !== 0
                    ? _c("workflow-audit", {
                        attrs: {
                          "page-key": "discharging_cargo",
                          "business-key": _vm.$route.query.stockOutId,
                          "proc-inst-id": _vm.$route.query.procInstId,
                          variable: {
                            businessId: _vm.$route.query.stockOutId,
                            motif:
                              _vm.outWarehouseDetail.enterpriseShortName +
                              "-" +
                              _vm.outWarehouseDetail.warehouseName
                          },
                          "view-mode": true
                        },
                        on: {
                          confirmAudit: function($event) {
                            return _vm.utils.backReturn(
                              _vm.$route.query.messageType
                            )
                          }
                        }
                      })
                    : _c("workflow-audit", {
                        attrs: {
                          "page-key": "discharging_cargo",
                          "business-key": _vm.$route.query.stockOutId,
                          "task-id": _vm.$route.query.taskId,
                          "proc-inst-id": _vm.$route.query.procInstId,
                          variable: {
                            businessId: _vm.$route.query.stockOutId,
                            motif:
                              _vm.outWarehouseDetail.enterpriseShortName +
                              "-" +
                              _vm.outWarehouseDetail.warehouseName
                          }
                        },
                        on: {
                          confirmAudit: function($event) {
                            return _vm.utils.backReturn(
                              _vm.$route.query.messageType
                            )
                          }
                        }
                      })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "van-row",
        { staticClass: "fixed-btn-bottom", attrs: { gutter: "20" } },
        [
          _c("van-button", {
            attrs: { type: "info", color: "#1373CC", block: "", text: "返回" },
            on: {
              click: function($event) {
                return _vm.utils.backReturn(_vm.$route.query.messageType)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }